import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { carouselItem } from "../Carousel/carousel.const";
import HorizonCarousel from "../Carousel/HorizonCarousel";
import VerticalCarousel from "../Carousel/VerticalCarousel";
import CarouselSelected from "../CarouselSelected/CarouselSelected";

export default function CarouselView() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedSrc, setSelectedSrc] = useState(carouselItem[0]);

  const handleIndexChange = (index: number) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    setSelectedSrc(carouselItem[selectedIndex]);
  }, [selectedIndex]);
  return (
    <Container>
      <VerticalWrap>
        <VerticalCarousel handleIndexChange={handleIndexChange} />
      </VerticalWrap>
      <CarouselSelected src={selectedSrc.example} />
      <HorizonWrap>
        <HorizonCarousel handleIndexChange={handleIndexChange} />
      </HorizonWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  gap: 30px;

  @media (min-width: 1100px) {
    flex-direction: row;
    gap: 0;
  }
`;

const VerticalWrap = styled.div`
  display: none;

  @media (min-width: 1100px) {
    display: flex;
  }
`;

const HorizonWrap = styled.div`
  @media (min-width: 1100px) {
    display: none;
  }
`;
