import React, { useState, useEffect } from "react";
import ImageModal from "../ImageModal/ImageModal";
import "./LevelInfo.css";
import levelImage1 from "../../assets/images/flags.png"; // Level 이미지 1
import levelImage2 from "../../assets/images/flags.png"; // Level 이미지 2

const LevelInfo = () => {
  const [modalImage, setModalImage] = useState<string | null>(null);
  const [animateLevel, setAnimateLevel] = useState(false);

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  // 애니메이션 설정
  useEffect(() => {
    setTimeout(() => {
      setAnimateLevel(true);
    }, 300); // Level 애니메이션 시작 시간
  }, []);

  return (
    <section className="level-info">
      {/* 큰 타이틀 */}
      <h2 className="title">
        <span className="nohighlight">학습 레벨 정보</span>
        <span className="highlight">에 대해 알아보세요</span>
      </h2>

      {/* 설명 */}
      <p className="description">
        각 레벨의 커리큘럼은 학습자의 수준에 맞춰 구성됩니다.
        <br />
        유치원생부터 중학생까지 체계적인 학습을 제공합니다.
      </p>

      {/* Level 이미지 섹션 */}
      <div className={`level-images ${animateLevel ? "fade-in-left" : ""}`}>
        <div className="images-column">
          <img
            src={levelImage1}
            alt="Level 이미지 1"
            onClick={() => openModal(levelImage1)}
          />
          <img
            src={levelImage2}
            alt="Level 이미지 2"
            onClick={() => openModal(levelImage2)}
          />
        </div>
      </div>

      {/* 모달 */}
      {modalImage && (
        <ImageModal
          imageSrc={modalImage}
          altText="확대된 이미지"
          onClose={closeModal}
        />
      )}
    </section>
  );
};

export default LevelInfo;
