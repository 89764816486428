import React from "react";
import "./ProgramIntroductionSteps.css";
import step01 from "../../../assets/images/step01.png";
import step02 from "../../../assets/images/step02.png";
import step03 from "../../../assets/images/step03.png";
import step04 from "../../../assets/images/step04.png";
import step05 from "../../../assets/images/step05.png";
import step06 from "../../../assets/images/step06.png";
import step07 from "../../../assets/images/step07.png";
import step08 from "../../../assets/images/step08.png";

const ProgramIntroductionSteps: React.FC = () => {
  return (
    <section className="program-introduction-steps">
      <div className="title-box">
        <h1 className="steps-title">
          영어 읽기의 <span className="highlight">시작과 완성</span>, <br />
          토들리에로 준비하세요!
        </h1>
        <p className="steps-subtitle">
          토들리에의 다양한 레벨의 원서로 영어 읽기 걸음마부터 달리기까지
          <br />
          영어 읽기 실력을 차근차근 쌓아갈 수 있습니다.
        </p>
      </div>
      <div className="step-box">
        <div className="step-content">
          <div className="bulletin-header">1. 기초다지기</div>
          <img className="step-image" src={step01} alt="단계 이미지" />
          <p className="step-description">
            간단한 기본 어휘 테스트를 통해 학습자의 현재 읽기 수준을 파악하여{" "}
            <br />
            카테고리별 원서의 읽기 레벨을 설정합니다.
          </p>
        </div>

        <div className="step-content">
          <div className="bulletin-header">2. 도서 선택</div>
          <img className="step-image" src={step02} alt="단계 이미지" />
          <p className="step-description">
            매주 카테고리별 선정 도서를 "오늘의 읽기 책"에서 확인하며, <br />
            흥미를 유도하고 읽기 목표를 설정합니다.
          </p>
        </div>

        <div className="step-content">
          <div className="bulletin-header">3. 첫 읽기</div>
          <img className="step-image" src={step03} alt="단계 이미지" />
          <p className="step-description">
            전체 읽기 버튼을 통해 오디오 음성과 자동페이지 넘김으로 1차 읽기를
            진행하여, <br />
            아이가 텍스트에 친숙해지고 전체 내용을 파악할 수 있도록 합니다..
          </p>
        </div>

        <div className="step-content">
          <div className="bulletin-header">4. 집중 읽기</div>
          <img className="step-image" src={step04} alt="단계 이미지" />
          <p className="step-description">
            전체 읽기 버튼을 통해 오디오 음성과 자동페이지 넘김으로 1차 읽기를
            진행하여, <br />
            아이가 텍스트에 친숙해지고 전체 내용을 파악할 수 있도록 합니다..
          </p>
        </div>

        <div className="step-content">
          <div className="bulletin-header">5. 언어 확장 학습</div>
          <img className="step-image" src={step05} alt="단계 이미지" />
          <p className="step-description">
            영어 원서와 한국어 번역본을 교차로 읽으며, 어휘와 표현에 대한
            이해도를 높이고, <br />두 언어의 차이를 통해 폭넓은 이해를 돕습니다.
          </p>
        </div>

        <div className="step-content">
          <div className="bulletin-header">6. AI 맞춤 학습</div>
          <img className="step-image" src={step06} alt="단계 이미지" />
          <p className="step-description">
            AI 튜터와 상호작용하여 원서 내용을 확장하고,
            <br />
            학습 중 궁금한 내용을 해결하여 깊이 있는 이해를 지원합니다.
          </p>
        </div>

        <div className="step-content">
          <div className="bulletin-header">7. 스피킹 연습</div>
          <img className="step-image" src={step07} alt="단계 이미지" />
          <p className="step-description">
            원서의 문장을 듣고 따라 읽어 보는 연습을 통해
            <br />
            스피킹 능력을 키우고, 발음과 유창성을 향상시킵니다.
          </p>
        </div>

        <div className="step-content">
          <div className="bulletin-header">8. 기억하기</div>
          <img className="step-image" src={step08} alt="단계 이미지" />
          <p className="step-description">
            퀴즈 버튼을 클릭하여 원서와 관련된 퀴즈 10개를 풀며 읽은 도서의
            내용을
            <br />
            이해도를 확인하고 부족한 부분이 있는지 점검하는 시간을 갖습니다.
          </p>
        </div>
      </div>{" "}
      {/* step-box */}
    </section>
  );
};

export default ProgramIntroductionSteps;
