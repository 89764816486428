import React, { useState, useEffect } from "react";
import ImageModal from "../ImageModal/ImageModal";
import "./Explanations.css";
import readingImage1 from "../../assets/images/reading_image1.png"; // 첫 번째 Reading 이미지
import readingImage2 from "../../assets/images/reading_image2.png"; // 두 번째 Reading 이미지
import speakingImage1 from "../../assets/images/speaking_image1.png"; // 첫 번째 Speaking 이미지
import speakingImage2 from "../../assets/images/speaking_image2.png"; // 두 번째 Speaking 이미지

const Explanations = () => {
  const [modalImage, setModalImage] = useState<string | null>(null);
  const [animateReading, setAnimateReading] = useState(false);
  const [animateSpeaking, setAnimateSpeaking] = useState(false);

  const openModal = (imageSrc: string) => {
    setModalImage(imageSrc);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  // 애니메이션 설정 (마운트 시)
  useEffect(() => {
    setTimeout(() => {
      setAnimateReading(true);
    }, 300); // Reading 애니메이션 시작 시간

    setTimeout(() => {
      setAnimateSpeaking(true);
    }, 600); // Speaking 애니메이션 시작 시간
  }, []);

  return (
    <section className="explanations">
      {/* 큰 타이틀 */}
      <h2 className="title">
      <span className="nohighlight">독보적인 이중언어 원서를 통해</span>
      <span className="highlight">언어 전환 능력과 어휘력 확장</span>{" "}
      </h2>

      {/* 두 줄 텍스트 */}
      <p className="description">
        토들리에만의 학습법 토들리에만의 학습법 토들리에만의 학습법
        <br />
        토들리에만의 학습법 토들리에만의 학습법 토들리에만의 학습법
      </p>

      {/* Reading과 Speaking 구분 */}
      <div className="explanations-container">
        {/* Reading 섹션 */}
        <div
          className={`explanation reading ${
            animateReading ? "fade-in-left" : ""
          }`}
        >
          <h3 className="section-title">Reading</h3>
          <div className="images-column">
            <img
              src={readingImage1}
              alt="Reading 이미지 1"
              onClick={() => openModal(readingImage1)}
            />
            <img
              src={readingImage2}
              alt="Reading 이미지 2"
              onClick={() => openModal(readingImage2)}
            />
          </div>
        </div>

        {/* Speaking 섹션 */}
        <div
          className={`explanation speaking ${
            animateSpeaking ? "fade-in-right" : ""
          }`}
        >
          <h3 className="section-title">Speaking</h3>
          <div className="images-column">
            <img
              src={speakingImage1}
              alt="Speaking 이미지 1"
              onClick={() => openModal(speakingImage1)}
            />
            <img
              src={speakingImage2}
              alt="Speaking 이미지 2"
              onClick={() => openModal(speakingImage2)}
            />
          </div>
        </div>
      </div>

      {/* 모달 */}
      {modalImage && (
        <ImageModal
          imageSrc={modalImage}
          altText="확대된 이미지"
          onClose={closeModal}
        />
      )}
    </section>
  );
};

export default Explanations;
