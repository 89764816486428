import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import CarouselItem from "./CarouselItem";

/**
----------------------------------------------- 🛠️ 타입 🛠️ -----------------------------------------------
*/
interface IAutoPlayCarousel {
  direction?: "right" | "left";
  source: any[];
  handleClickSliderImg: (imgSource: string) => void;
}

/**
----------------------------------------------- 🎨 스타일 🎨 -----------------------------------------------
*/
const Layout = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const Track = styled.div<{ direction: "right" | "left" }>`
  display: flex;
  position: absolute;
  left: 0;
  justify-content: flex-start;
  height: 100%;
  align-items: center;
  width: max-content;
  gap: 12px;
`;

/**
----------------------------------------------- 🖼️ 렌더링 🖼️ -----------------------------------------------
*/

const AutoPlayCarousel = ({
  direction = "right",
  source,
  handleClickSliderImg,
}: IAutoPlayCarousel) => {
  const trackRef = useRef<HTMLDivElement>(null);
  const clonedSource = [...source, ...source];

  useEffect(() => {
    const track = trackRef.current;
    if (!track) return;

    let startPosition = direction === "right" ? 0 : -track.offsetWidth / 2;
    let animationFrameId: number;

    const speed = 0.3;

    const animate = () => {
      if (direction === "right") {
        startPosition -= speed;
        if (startPosition <= -track.offsetWidth / 2) {
          startPosition = 0;
        }
      } else {
        startPosition += speed;
        if (startPosition >= 0) {
          startPosition = -track.offsetWidth / 2;
        }
      }

      track.style.transform = `translateX(${startPosition}px)`;
      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationFrameId);
  }, [direction]);

  return (
    <Layout className="carousel-container">
      <Track ref={trackRef} direction={direction}>
        {clonedSource.map((item, index) => (
          <CarouselItem
            key={`carousel-item-${direction}-${index}`}
            onClick={() => handleClickSliderImg(item)}
            imgUrl={item}
          />
        ))}
      </Track>
    </Layout>
  );
};

export default AutoPlayCarousel;
