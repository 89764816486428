import React, { useEffect, useRef } from "react";
import "./KeyPoints.css";
import typewriter from "../../assets/images/typewriter.png";
import flags from "../../assets/images/flags.png";
import tutorRobot from "../../assets/images/tutor_robot.png";
import teachers from "../../assets/images/teachers.png";
import school from "../../assets/images/school.png";

const KeyPoints = () => {
  const keypointsRef = useRef<HTMLDivElement[]>([]); // 여러 개의 keypoint 참조
  // 각 글자를 개별 <span> 요소로 분리
  const text = "리딩으로 커지는 스피킹 실력!";
  const letters = text.split("").map((char, index) => {
    if (char === " ") {
      // 공백일 경우는 <span>을 추가하지 않고 공백 그대로
      return <span key={index}>&nbsp;</span>;
    } else {
      // 공백이 아닐 경우만 애니메이션을 적용
      return (
        <span key={index} className={`bounce delay-${index % 10}`}>
          {char}
        </span>
      );
    }
  });
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          } else {
            entry.target.classList.remove("show");
          }
        });
      },
      {
        threshold: 0.1, // 화면에 10% 이상 나타났을 때 애니메이션 실행
      }
    );

    // keypointsRef.current를 로컬 변수에 저장
    const currentKeypoints = keypointsRef.current;

    currentKeypoints.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      // cleanup 시점에서도 로컬 변수로 접근
      currentKeypoints.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  return (
    <section className="keypoints">
      <h2>{letters}</h2>
      <div className="keypoints-container">
        <div
          className="keypoint delay-0"
          ref={(el) => (keypointsRef.current[0] = el!)}
        >
          <div className="circle">
            <img src={typewriter} alt="Type Writer" />
            <h3>다양한 작가</h3>
            <p className="small-text">개인 맞춤형</p>
          </div>
        </div>

        <div
          className="keypoint delay-1"
          ref={(el) => (keypointsRef.current[1] = el!)}
        >
          <div className="circle circle_image_wide">
            <img src={flags} alt="Flags" />
            <h3>영국, 미국, 한국</h3>
            <p className="small-text">프리미엄 컨텐츠</p>
          </div>
        </div>

        <div
          className="keypoint delay-2"
          ref={(el) => (keypointsRef.current[2] = el!)}
        >
          <div className="circle">
            <img src={tutorRobot} alt="AI Tutor" />
            <h3>AI 튜터</h3>
            <p className="small-text">개인 맞춤형</p>
          </div>
        </div>
        <div
          className="keypoint delay-3"
          ref={(el) => (keypointsRef.current[3] = el!)}
        >
          <div className="circle">
            <img src={school} alt="School" />
            <h3>학습 기관 연계</h3>
            <p className="small-text">오프라인 학습 연계</p>
          </div>
        </div>
        <div
          className="keypoint delay-4"
          ref={(el) => (keypointsRef.current[4] = el!)}
        >
          <div className="circle">
            <img src={teachers} alt="Teachers" />
            <h3>방문 수업</h3>
            <p className="small-text">1:1 대면 방문 수업</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KeyPoints;
