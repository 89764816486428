import { styled } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useRef } from "react";
import type { Swiper as SwiperType } from "swiper"; // Swiper 타입 임포트
import CarouselArrow from "../CaroselArrow/CarouselArrow";
import { carouselItem } from "./carousel.const";
const ICON_SIZE = 230;
// 모험, 과학1 예시 이미지가 없음

interface VerticalCarouselProps {
  handleIndexChange: (index: number) => void;
}
export default function VerticalCarousel({
  handleIndexChange,
}: VerticalCarouselProps) {
  const swiperRef = useRef<SwiperType | null>(null); // 타입 지정

  const handleNext = () => {
    console.log("다음", swiperRef.current);
    swiperRef.current?.slideNext();
  };

  const handlePrev = () => {
    swiperRef.current?.slidePrev();
  };

  const handleSlideTo = (index: number) => {
    swiperRef.current?.slideTo(index); // 해당 인덱스로 이동
    handleIndexChange(index); // 외부에서도 인덱스 업데이트
  };

  return (
    <SwiperWrap>
      <PrevBtnWrap>
        <CarouselArrow onClick={handlePrev} type="prev" />
      </PrevBtnWrap>
      <NextBtnWrap>
        <CarouselArrow onClick={handleNext} type="next" />
      </NextBtnWrap>
      <StyledSwiper
        onSwiper={(swiper: any) => (swiperRef.current = swiper)}
        onSlideChange={(swiper: any) => handleIndexChange(swiper.activeIndex)}
        modules={[Pagination]}
        pagination={{ clickable: true }}
        direction="vertical"
        slidesPerView={1}
        centeredSlides={true}
        spaceBetween={20}
      >
        {carouselItem.map((item, i) => (
          <SwiperSlide key={i} onClick={() => handleSlideTo(i)}>
            <Card src={item.icon} alt={`icon_${i}`} />
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </SwiperWrap>
  );
}

const SwiperWrap = styled.div`
  display: flex;
  width: 500px;
  overflow: visible;
  /* background-color: yellow; */
  position: relative;
  justify-content: center;
`;

const Card = styled.img`
  width: ${`${ICON_SIZE}px`};
  height: ${`${ICON_SIZE}px`};
  object-fit: contain;
`;

const StyledSwiper = styled(Swiper)`
  width: ${`${ICON_SIZE}px`};
  height: ${`${ICON_SIZE}px`};
  overflow: visible;
  .swiper-wrapper {
    gap: 20;
  }
  .swiper-pagination {
    left: -80px;
    width: fit-content;

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
    }

    .swiper-pagination-bullet-active {
      background-color: #e26d3d;
    }
  }

  .swiper-slide-active {
    transition: opacity 1s ease; /* 0.3초 동안 부드럽게 변화 */
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    opacity: 0.3;
    transition: opacity 0.6s ease; /* 0.3초 동안 부드럽게 변화 */
  }
`;

const PrevBtnWrap = styled.div`
  position: absolute;
  width: 25px;
  z-index: 10;
  top: -5px;
`;

const NextBtnWrap = styled.div`
  position: absolute;
  width: 25px;
  z-index: 10;
  bottom: -5px;
`;
