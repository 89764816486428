import React from "react";
import "./SubProgramsGeneral.css";
// import ebook_01 from "../../assets/images/subPrograms/ebook_01.png";
// import ebook_02 from "../../assets/images/subPrograms/ebook_02.png";
// import ebook_03 from "../../assets/images/subPrograms/ebook_03.png";
// import ebook_04 from "../../assets/images/subPrograms/ebook_04.png";
// import ebook_05 from "../../assets/images/subPrograms/ebook_05.png";
// import ebook_06 from "../../assets/images/subPrograms/ebook_06.png";
// import ebook_07 from "../../assets/images/subPrograms/ebook_07.png";
// import ebook_08 from "../../assets/images/subPrograms/ebook_08.png";
// import ebook_09 from "../../assets/images/subPrograms/ebook_09.png";
// import ebook_10 from "../../assets/images/subPrograms/ebook_10.png";
// import ebook_11 from "../../assets/images/subPrograms/ebook_11.png";
// import ebook_12 from "../../assets/images/subPrograms/ebook_12.png";
// import ebook_13 from "../../assets/images/subPrograms/ebook_13.png";
import Footer from "../Footer/Footer";

const ebook_01 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/e-book+2/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_ebook_1.png";
const ebook_02 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/e-book+2/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_ebook_2.png";
const ebook_03 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/e-book+2/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_ebook_3.png";
const ebook_04 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/e-book+2/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_ebook_4.png";
const ebook_05 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/e-book+2/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_ebook_5.png";
const ebook_06 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/e-book+2/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_ebook_6.png";
const ebook_07 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/e-book+2/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_ebook_7.png";
const ebook_08 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/e-book+2/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_ebook_8.png";
const ebook_09 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/e-book+2/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_ebook_9.png";
const ebook_10 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/e-book+2/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_ebook_10.png";
const ebook_11 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/e-book+2/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_ebook_11.png";
const ebook_12 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/e-book+2/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_ebook_12.png";
const ebook_13 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/e-book+2/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_ebook_13.png";

const EBook = () => {
  return (
    <>
      <section className="section-banner ebook">
        <div className="image-container">
          <img src={ebook_01} alt="ebook Explanation 1" />
          <img src={ebook_02} alt="ebook Explanation 2" />
          <img src={ebook_03} alt="ebook Explanation 3" />
          <img src={ebook_04} alt="ebook Explanation 4" />
          <img src={ebook_05} alt="ebook Explanation 5" />
          <img src={ebook_06} alt="ebook Explanation 6" />
          <img src={ebook_07} alt="ebook Explanation 7" />
          <img src={ebook_08} alt="ebook Explanation 8" />
          <img src={ebook_09} alt="ebook Explanation 9" />
          <img src={ebook_10} alt="ebook Explanation 10" />
          <img src={ebook_11} alt="ebook Explanation 11" />
          <img src={ebook_12} alt="ebook Explanation 12" />
          <img src={ebook_13} alt="ebook Explanation 13" />
        </div>
      </section>
      <Footer /> {/* Footer 추가 */}
    </>
  );
};

export default EBook;
