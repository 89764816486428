import React from "react";
import "./ImageModal.css";
import xbtn from "../../assets/images/x_button.png";

// ImageModal Props 타입 정의
interface ImageModalProps {
  imageSrc: string;
  altText: string;
  onClose: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({
  imageSrc,
  altText,
  onClose,
}) => {
  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <img src={imageSrc} alt={altText} className="modal-image" />
        <button className="modal-close" onClick={onClose}>
          {/* &times; X 문자를 사용 */}
          <img src={xbtn} alt="x" />
        </button>
      </div>
    </div>
  );
};

export default ImageModal;
