import { styled } from "styled-components";
import arrow_icon from "../../../assets/images/carousel/arrow.png";

interface CarouselArrowProps {
  onClick: () => void;
  type: "next" | "prev" | "left" | "right";
}

export default function CarouselArrow({ onClick, type }: CarouselArrowProps) {
  return <Icon $type={type} onClick={onClick} src={arrow_icon} alt={type} />;
}

const Icon = styled.img<{ $type: "next" | "prev" | "left" | "right" }>`
  transform: ${({ $type }) =>
    $type === "next"
      ? "rotate(180deg)"
      : $type === "right"
      ? "rotate(90deg)"
      : $type === "left"
      ? "rotate(-90deg)"
      : "none"};
  object-fit: contain;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
