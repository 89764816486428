import React from "react";
import "./ProgramIntroductionOnlineOffline.css";
import naverBlogIcon from "../../../assets/images/naver_blog.png";
import naverCafeIcon from "../../../assets/images/naver_cafe.png";
import instagramIcon from "../../../assets/images/instagram.png";
import kakaoTalkIcon from "../../../assets/images/kakao-talk.png";
import youtubeIcon from "../../../assets/images/youtube.png";
import study_1_img from "../../../assets/images/onoffline/study_1.png";
import study_2_img from "../../../assets/images/onoffline/study_2.png";
import study_3_img from "../../../assets/images/onoffline/study_3.png";

const ProgramIntroductionOnlineOffline: React.FC = () => {
  return (
    <section className="onOffline-container">
      <div className="program-intro-container">
        <h1 className="program-intro-title">
          어디서도 볼 수 없는 독보적인 <br />
          <span className="highlight">온라인과 오프라인 융합 교육 서비스</span>
        </h1>
        <p className="program-intro-subtitle">
          오프라인 제휴 기관 수업이나 1:1 방문 수업을 통해, 읽기 학습 지도와
          기초 영어 교육부터
          <br />
          영어 기반 창의 인재 융합 교육까지 선생님과 함께, 친구들과 함께 학습할
          수 있습니다.
        </p>

        <div className="program-options">
          <p className="program-options-button">학습 방법 선택</p>

          <div className="program-option-cards">
            <div className="program-option-card">
              <img src={study_1_img} alt="오프라인 기관 방문" />

              <div className="program-option-card-box">
                <h2 className="program-option-title">
                  친구들과 함께 갈래요!
                  <br />
                  <span className="option-highlight">오프라인 기관 방문</span>
                </h2>
                <p className="program-option-description">
                  5인 이하 소규모 정예
                  <br />
                  수업으로 친구들과 함께
                  <br />
                  학습할 수 있습니다.
                </p>
              </div>
            </div>

            <div className="program-option-card">
              <img src={study_2_img} alt="1:1 방문 선생님" />

              <div className="program-option-card-box">
                <h2 className="program-option-title">
                  우리 집으로 와주세요!
                  <br />
                  <span className="option-highlight">1:1 방문 선생님</span>
                </h2>
                <p className="program-option-description">
                  영어 교육 전문가가
                  <br />
                  집으로 직접 방문하여
                  <br />
                  맞춤형 수업을 진행합니다.
                </p>
              </div>
            </div>

            <div className="program-option-card">
              <img src={study_3_img} alt="토들리에 활용" />

              <div className="program-option-card-box">
                <h2 className="program-option-title">
                  혼자 할 수 있어요!
                  <br />
                  <span className="option-highlight">토들리에 활용</span>
                </h2>
                <p className="program-option-description">
                  온라인 전용 프로그램을 통해 학습하며,
                  <br />
                  학습 중 게 및 진행표를 확인합니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-container">
        <h2 className="contact-title">
          걱정하지 마세요.
          <br />
          <span className="highlight">토들리에는</span> 언제든
          <span className="highlight">연락</span> 가능하니까요!
        </h2>
        <p className="contact-description">
          공휴일과 주말에도 학습 상담, 활용 문의 등 교육 관련 상담은 물론,
          <br />
          기관 도입과 결제 문의까지 언제든지 연락 주세요
        </p>

        <button className="contact-button">실시간 상담</button>
        <div className="contact-icons">
          <a href="https://naver.com" target="_blank" rel="noopener noreferrer">
            <img
              src={naverBlogIcon}
              alt="Naver_blog"
              className="contact-icon"
            />
          </a>
          <a href="https://naver.com" target="_blank" rel="noopener noreferrer">
            <img
              src={naverCafeIcon}
              alt="Naver_cafe"
              className="contact-icon"
            />
          </a>

          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagramIcon} alt="Instagram" className="contact-icon" />
          </a>
          <a href="https://kakao.com" target="_blank" rel="noopener noreferrer">
            <img src={kakaoTalkIcon} alt="Kakao" className="contact-icon" />
          </a>

          <a
            href="https://youtube.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={youtubeIcon} alt="YouTube" className="contact-icon" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default ProgramIntroductionOnlineOffline;
