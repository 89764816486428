import api from ".";

// 공지사항&이벤트 리스트 조회
export const getNoticeList = async (
  pageNum: number,
  perPage: number,
  type: string
) => {
  const { data } = await api.get(
    `/support/landing/notice?pageNum=${pageNum}&perPage=${perPage}&type=${type}`
  );
  return data;
};

// 공지사항&이벤트 상세 조회
