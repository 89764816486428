import React from "react";
import "./ProgramIntroductionContents.css";
import {
  idc_img,
  books_img,
  make_labs_img,
  question_1_img,
  // question_2_img,
  note_img,
} from "../../../assets/images/chang_yi";

const ProgramIntroductionContents: React.FC = () => {
  return (
    <section className="program-introduction-steps">
      <div className="title-box">
        <h1 className="steps-title">
          영어 기반 <span className="highlight">창의인재융합 교육</span>
          <br />
          미디어와 체험형 콘텐츠도 놓치지 마세요.
        </h1>
        <p className="steps-subtitle">
          영어 기반 창의 인재 융합 콘텐츠는 영상 미디어, 서적, 만들기 및 실험 등
          <br />
          다양한 체험 활동을 통해 영어 학습 환경을 제공하며,
          <br />
          과학적 사고력과 이해력을 키우는 프로그램입니다.
        </p>
      </div>
      <div className="step-box">
        <div className="step-content-pic">
          <div className="bulletin-header">1. IDC 시청하기</div>
          <img className="step-image-pic" src={idc_img} alt="단계 이미지" />
          <p className="step-description-pic">
            학습 진행 전, 인터랙티브 디지털 콘텐츠를 <br />
            시청하여 전반적인 학습 이해도를 높입니다.
          </p>
        </div>

        <div className="step-content-pic">
          <div className="bulletin-header">2. 교재 활용하기</div>
          <img className="step-image-pic" src={books_img} alt="단계 이미지" />
          <p className="step-description-pic">
            영상에서 학습한 내용을 교재 자료와 <br />
            함께 복습하여 이해를 강화합니다.
          </p>
        </div>

        <div className="step-content-pic">
          <div className="bulletin-header">3. 만들기 & 실험하기</div>
          <img
            className="step-image-pic"
            src={make_labs_img}
            alt="단계 이미지"
          />
          <p className="step-description-pic">
            전체 읽기 버튼을 통해 오디오 음성과 자동 페이지 넘김으로 1차 읽기를
            진행하여,
            <br />
            아이가 텍스트에 친숙해지고 전체 내용을 파악할 수 있도록 합니다.
          </p>
        </div>

        <div className="step-content-pic">
          <div className="bulletin-header">4. 문제 풀기</div>
          <img
            className="step-image-pic"
            src={question_1_img}
            alt="단계 이미지"
          />
          <p className="step-description-pic">
            눈으로 읽고 오디오로 들으며 2차 읽기를 진행, <br />
            이해력과 어휘 인지를 높이고 심화된 학습을 돕습니다.
          </p>
        </div>

        <div className="step-content-pic">
          <div className="bulletin-header">5. 수업 노트 작성</div>
          <img className="step-image-pic" src={note_img} alt="단계 이미지" />
          <p className="step-description-pic">
            책의 마지막 페이지에 오늘 배운 핵심 단어와
            <br />
            내용을 정리하고, 관련 삽화를 그려보며 복습합니다.
          </p>
        </div>
      </div>
      {/* step-box */}
    </section>
  );
};

export default ProgramIntroductionContents;
