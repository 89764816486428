import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import logo from "../../assets/images/logo.png";
import "./Header.css";

interface HeaderProps {
  onProgramClick: (boolean:boolean) => void;
  isProgramVisible:boolean
}

const Header: React.FC<HeaderProps> = ({ onProgramClick,isProgramVisible }) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showProgramLinks, setShowProgramLinks] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const {pathname} = useLocation()
  console.log(pathname)
  // 프로그램 링크를 배열로 관리

  // KHR : path명은 소문자 작성 및 띄어쓰기가 없이 작성해야 오류가 발생하지 않습니다. 
  // KHR : 그래서 아래 path명 일부 수정하였습니다.
  const programLinks = [
    { name: "Creative Science English", path: "/science2" },
    { name: "Literacy", path: "/literacy2" },
    { name: "e-Book", path: "/ebook" },
    { name: "Kids News", path: "/kids-news" },
  ];

  const isInSubProgram = programLinks.some(item=>item.path===pathname) // 현재 SubProgram페이지에 있는지 확인
  
  // 로그인 함수
  const handleClickLogin = () => {
    window.open("https://edu.toddlier.co.kr/signin", "_blank");
  };


  // 로고 클릭 함수
  const handleLogoClick = () => {
    closeFadeOutSubmenu()
    onProgramClick(false)
    navigate("/");
  };

  // SubProgram 닫는 함수
  const closeFadeOutSubmenu = ()=>{
    if(showProgramLinks){
      setIsAnimating(true);
      setTimeout(()=>{
        setShowProgramLinks(false);
            setIsAnimating(false);
      },300)
    }
  }

  // 프로그램 소개 버튼 클릭 핸들러
  const handleProgramClick = () => {
    // 현재 SubProgram페이지에 있는 경우
    if(isInSubProgram){
      navigate('/')
      return
    }
    // 현재 SubProgram페이지에 없고, 메인이 아닌 경우
    if(!isInSubProgram&&pathname!=='/'){
      console.log("여기입니다.")
      closeFadeOutSubmenu()
      navigate('/')
      setShowProgramLinks(true)
      return
    }
    // 그 외
    setShowProgramLinks(true)
    onProgramClick(true);
  };


  // 이벤트, 공지사항 클릭 함수
  const handleClickMenuButton = (path:"/event"|"/notice")=>{
    closeFadeOutSubmenu()
    navigate(path)
  }

  // SubProgram Click 함수
  const handleLinkClick = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const handleScroll = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        setIsScrolled(window.scrollY > 50);
      }, 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return (
    <header className={`header ${isScrolled ? "scrolled" : ""}`}>
      <div
        className="logo"
        onClick={handleLogoClick}
        style={{ cursor: "pointer" }}
      >
        <img src={logo} alt="Logo" />
      </div>
      <nav className="menu">
        <button
          onClick={handleProgramClick}
          className="menu-link"
          style={{
            background: "none",
            border: "none",
            padding: 0,
            font: "inherit",
            color: "inherit",
            cursor: "pointer",
          }}
        >
          프로그램 소개
        </button>
        {showProgramLinks && (
          <div className={`program-links ${isAnimating ? "fade-out" : ""}`}>
            {programLinks.map((link, index) => (
              <span
                key={index}
                className="program-link"
                onClick={() => handleLinkClick(link.path)}
              >
                {link.name}
              </span>
            ))}
          </div>
        )}

        {/* KHR :  공지사항, 이벤트 버튼 만들어 뒀습니다. */}
        <button
          onClick={()=>handleClickMenuButton('/notice')}
          className="menu-link"
          style={{
            background: "none",
            border: "none",
            padding: 0,
            font: "inherit",
            color: "inherit",
            cursor: "pointer",
          }}
        >
          공지사항
        </button>
        <button
          onClick={()=>handleClickMenuButton('/event')}
          className="menu-link"
          style={{
            background: "none",
            border: "none",
            padding: 0,
            font: "inherit",
            color: "inherit",
            cursor: "pointer",
          }}
        >
          이벤트
        </button>

        <button onClick={handleClickLogin} className="header_button">
          로그인
        </button>
      </nav>
      <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
        ☰
      </div>
      {menuOpen && (
        <HamburgerMenu
          programLinks={programLinks} // programLinks 배열을 props로 전달
          onLinkClick={(path) => {
            handleLinkClick(path);
            setMenuOpen(false); // 링크 클릭 시 햄버거 메뉴 닫기
          }}
        />
      )}
    </header>
  );
};

export default Header;
