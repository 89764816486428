import {
  adventure_icon,
  ccss_icon,
  conversation_icon,
  daily_icon,
  literacy1_icon,
  literacy2_icon,
  math_icon,
  non_fiction_icon,
  nuri_icon,
  science1_icon,
  science2_icon,
  sf_icon,
} from "../../../assets/images/carousel/icon/index";

import {
  adventure_example,
  ccss_example,
  conversation_example,
  daily_example,
  literacy1_example,
  literacy2_example,
  math_example,
  non_fiction_example,
  nuri_example,
  science1_example,
  science2_example,
  sf_example,
} from "../../../assets/images/carousel/example/index";

export const carouselItem = [
  {
    icon: nuri_icon,
    example: nuri_example,
  },
  {
    icon: literacy1_icon,
    example: literacy1_example,
  },
  {
    icon: literacy2_icon,
    example: literacy2_example,
  },
  {
    icon: math_icon,
    example: math_example,
  },
  {
    icon: science1_icon,
    example: science1_example,
  },
  {
    icon: science2_icon,
    example: science2_example,
  },
  {
    icon: ccss_icon,
    example: ccss_example,
  },
  {
    icon: conversation_icon,
    example: conversation_example,
  },
  {
    icon: daily_icon,
    example: daily_example,
  },
  {
    icon: adventure_icon,
    example: adventure_example,
  },
  {
    icon: non_fiction_icon,
    example: non_fiction_example,
  },
  {
    icon: sf_icon,
    example: sf_example,
  },
];
