import React from "react";
import { styled } from "styled-components";
import { mediaCategoryArr, mediaContentsArr, unitArr } from "./array.const";
import "./ProgramIntroductionContentsTable.css";

const CONTENT_HEIGHT = 95;

const ProgramIntroductionContentsTable2: React.FC = () => {
  return (
    <>
      <h1 className="content-program-title">미디어 컨텐츠 프로그램</h1>
      <Container>
        <HeadSection>
          <HeaderBox>카테고리</HeaderBox>
          <ContentGridColumn>
            {mediaCategoryArr.map((item, i) => (
              <CategoryBox key={i} $bg={item.bg}>
                {item.text}
              </CategoryBox>
            ))}
          </ContentGridColumn>
        </HeadSection>
        <BodySection>
          <UnitRow>
            {unitArr.map((unit, i) => (
              <UnitBox key={i}>{unit}</UnitBox>
            ))}
          </UnitRow>
          <ContentGridColumn>
            {mediaContentsArr.map((row, i) => (
              <BodyRow key={i} $small={i === 1 || i === 2}>
                {row.map((content, j) => {
                  return (
                    <ContentBox
                      $span={content.span}
                      $bg={content.bg}
                      $color={content.color}
                      key={j}
                    >
                      {content.text}
                    </ContentBox>
                  );
                })}
              </BodyRow>
            ))}
          </ContentGridColumn>
        </BodySection>
      </Container>
    </>
  );
};

export default ProgramIntroductionContentsTable2;

const Container = styled.div`
  width: 100%;
  padding: 0px 10px;
  display: flex;
  gap: 10px;
  box-sizing: border-box;
  margin-bottom: 100px;
  div {
    box-sizing: border-box;
  }
`;

const HeadSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const BodySection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  overflow-x: auto;
`;
const UnitRow = styled.div<{ $small?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  min-width: 1410px;
  /* grid-row: span ${({ $small }) => ($small ? 1 : 2)}; */
`;

const BodyRow = styled.div<{ $small?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  min-width: 1410px;
  grid-row: span ${({ $small }) => ($small ? 1 : 2)};
  gap: 5px;
`;

const ContentGridColumn = styled.div`
  display: grid;
  grid-template-rows: repeat(10, minmax(0, 1fr));
  row-gap: 10px;
  height: 500px;
`;

const HeaderBox = styled.div`
  width: 120px;
  height: 36px;
  /* font-size: 0.9rem; */
  font-weight: 700;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: black;
  background-color: #f1f1f1;
  word-wrap: break-word;
  text-align: center;
`;

const CategoryBox = styled.div<{
  $bg: string;
}>`
  font-size: 0.9rem;
  font-weight: 700;
  border-radius: 10px;
  width: 120px;
  height: ${`${CONTENT_HEIGHT}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: white;
  background-color: ${({ $bg }) => $bg};
  word-wrap: break-word;
  text-align: center;
  grid-row: span 2;
`;

const UnitBox = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  flex-shrink: 0;
  background-color: #dddddd;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid transparent;
  grid-column: span 2;

  /* 첫 번째 자식일 때 */
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  /* 마지막 자식일 때 */
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: none;
  }
`;

const ContentBox = styled.div<{
  $bg: string;
  $color: string;
  $span: number;
}>`
  box-sizing: border-box;
  width: 100%;
  /* height: ${`${CONTENT_HEIGHT}px`}; */
  background-color: ${({ theme, $bg }) => $bg};
  color: ${({ theme, $color }) => $color};
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  font-size: 0.8rem;
  align-items: center;
  text-align: center;
  word-break: keep-all;
  overflow-wrap: break-word;
  font-weight: 700;
  padding: 4px 7px;
  grid-column: span ${({ $span }) => $span || 1}; /* 기본 값은 1 */
  border-radius: 10px;
`;
