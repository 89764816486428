import React, { useState } from "react";
import PrivacyPolicyModal from "../PrivacyPolicy/PrivacyPolicy";
import "./Footer.css";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <footer className="footer">
      <div className="footer-left">
        <div className="footer-company">
          <span>네이처컴바인드 주식회사</span> {/* Bold 처리될 부분 */}
        </div>
        <div className="footer-info">
          <span>대표자: 동방진&nbsp;&nbsp;</span>
          <span>
            경기 화성시 동탄첨단산업1로 57, 인큐베이팅센터 701호&nbsp;&nbsp;
          </span>
          <span>사업자등록번호: 881-86-02075</span>
        </div>
        <div className="footer-contact">
          <span>전화번호: 1811-0729&nbsp;&nbsp;</span>
          <span>이메일: maintenance@toddlier.co.kr&nbsp;&nbsp;</span>
          <span>통신판매업 신고번호 : 제2021-화성동탄-1107호</span>
        </div>
      </div>

      <hr />

      <div className="footer-right">
        <span>회사소개</span>
        <span className="divider">|</span>
        <span
          onClick={openModal}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            textDecorationThickness: "1px", // 밑줄 두께 조절
            textUnderlineOffset: "3px", // 밑줄 간격 조절
          }}
        >
          개인정보처리방침
        </span>
      </div>

      <PrivacyPolicyModal isOpen={isModalOpen} onClose={closeModal} />
    </footer>
  );
};

export default Footer;
