import React, { useState } from "react";
import "./BulletinBoard.css";
import TextModal from "../TextModal/TextModel";
import { useQuery } from "@tanstack/react-query";
import { getNoticeList } from "../../api/axios/notice.api";

const BulletinBoard: React.FC = () => {
  const [isTextModalOpen, setIsTextModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");

  // 공지사항 데이터를 가져오는 useQuery 호출
  const {
    data: noticeData,
    isLoading: noticeLoading,
    error: noticeError,
  } = useQuery({
    queryKey: ["noticeList", 1, "notice"],
    queryFn: () => getNoticeList(1, 10, "notice"),
  });

  // 이벤트 데이터를 가져오는 useQuery 호출
  const {
    data: eventData,
    isLoading: eventLoading,
    error: eventError,
  } = useQuery({
    queryKey: ["eventList", 1, "event"],
    queryFn: () => getNoticeList(1, 10, "event"),
  });

  const handleOpenTextModal = (title: string, content: string) => {
    setModalTitle(title);
    setModalContent(content);
    setIsTextModalOpen(true);
  };

  const handleCloseTextModal = () => {
    setIsTextModalOpen(false);
    setModalTitle("");
    setModalContent("");
  };

  // notice
  if (noticeLoading) return <div>Loading...</div>;
  if (noticeError) return <div>Error loading notices.</div>;

  // event
  if (eventLoading) return <div>Loading...</div>;
  if (eventError) return <div>Error loading notices.</div>;

  return (
    <div className="bulletin-board">
      <h2 className="title">토들리에 소식</h2>

      <div className="bulletin-section">
        <div className="bulletin-item">
          <div className="bulletin-header">공지사항</div>
          <div className="bulletin-content">
            <p>[공지사항]</p>
            {noticeData?.data?.items?.length > 0 ? (
              noticeData.data.items.map((notice: any) => (
                <p
                  key={notice.id}
                  onClick={() =>
                    handleOpenTextModal(notice.title, notice.content)
                  }
                >
                  {notice.title}
                </p>
              ))
            ) : (
              <p>No notices available</p>
            )}
          </div>
        </div>

        <div className="bulletin-item">
          <div className="bulletin-header">이벤트</div>
          <div className="bulletin-content">
            <p>[이벤트]</p>
            {eventData?.data?.items?.length > 0 ? (
              eventData.data.items.map((notice: any) => (
                <p
                  key={notice.id}
                  onClick={() =>
                    handleOpenTextModal(notice.title, notice.content)
                  }
                >
                  {notice.title}
                </p>
              ))
            ) : (
              <p>No notices available</p>
            )}
          </div>
        </div>

        <div className="bulletin-item best-review">
          <div className="bulletin-header">베스트 리뷰</div>
          <div className="bulletin-content">
            <div className="review-image">이미지</div>
            <div className="review-text">
              <p>Ooo님 ★★★★★</p>
              <p>스토리가 재미있어서 영어 공부하는데 흥미를 얻어요</p>
            </div>
          </div>
        </div>

        <div className="bulletin-item">
          <div className="bulletin-header">방문 교사 지역</div>
          <div className="region-list">
            {["동탄 신도시", "동탄 신도시", "동탄 신도시", "동탄 신도시"].map(
              (region, index) => (
                <div
                  className={`region-item ${index < 2 ? "left" : "right"}`}
                  key={index}
                >
                  <p>{region}</p>
                </div>
              )
            )}
          </div>
        </div>

        <div className="bulletin-item  institution-case">
          <div className="bulletin-header">기관 도입 사례</div>
          <div className="bulletin-images">
            <div className="institution-image">이미지</div>
            <p>동탄 신도시점</p>
            <div className="institution-image">이미지</div>
            <p>동탄 신도시점</p>
          </div>
        </div>
      </div>

      {/* TextModal을 조건부로 렌더링 */}
      {isTextModalOpen && (
        <TextModal
          title={modalTitle}
          content={modalContent}
          onClose={handleCloseTextModal}
        />
      )}
    </div>
  );
};

export default BulletinBoard;
