import React from "react";
import CarouselView from "../UI/CarouselView/CarouselView";
import "./ProgramIntroduction.css";

interface ProgramIntroductionProps {
  id?: string;
}

const ProgramIntroduction: React.FC<ProgramIntroductionProps> = ({ id }) => {
  return (
    <section id={id} className="program-introduction">
      <div className="intro-section">
        <div className="inner-intro-section">
          <h1 className="intro-title">
            아이의 미래를 여는
            <br />
            영어 읽기의 시작
          </h1>
          <p className="intro-subtitle">
            토들리에가 걸음마부터 달리기까지, <br /> 언제나 곁에 있겠습니다.
          </p>
        </div>
      </div>

      {/* 두 번째 섹션 추가 */}
      <div className="second-section">
        <h2 className="second-title">
          <span className="highlight-orange">걸음마부터 달리기까지,</span>
          <br />
          아이들의 든든한
          <span className="highlight-orange">페이스 메이커</span>가
          되어드립니다!
        </h2>

        <div className="container">
          <div className="card">
            <div className="image"></div>
            <div className="text">
              기초 레벨 설정 후, <br />
              오디오북과 <br />
              따라 읽기 기능으로 <br />
              원서와 친숙해지기
            </div>
          </div>
          <div className="card">
            <div className="image"></div>
            <div className="text">
              한국어 번역 원서와 <br />
              교차 읽기로 <br />
              어휘와 표현에 대한 <br />
              이해도 높이기
            </div>
          </div>
          <div className="card">
            <div className="image"></div>
            <div className="text">
              원서 읽기 후 <br />
              AI 튜터링을 통해 <br />
              원서 내용을 확장하는 <br />
              심화 대화로 표현력 강화
            </div>
          </div>
          <div className="card">
            <div className="image"></div>
            <div className="text">
              다음 레벨로 업그레이드하여 <br />
              더 많은 문장과 <br />
              어휘가 포함된
              <br />
              원서 읽기 도전하기
            </div>
          </div>
        </div>
      </div>

      <div className="premium-content">
        <h1>
          <span>토들리에의 독보적인</span>
          <br />
          <span className="highlight">영어기반 창의융합콘텐츠</span>와 <br />
          <span className="highlight">프리미엄 원서</span>를 경험하세요.
        </h1>
        <p>
          다양한 주제의 원서를 읽으며 언어 능력을 향상시키는 것은 물론, <br />
          창의적인 상상력과 과학적 이해 능력을 함께 키울 수 있습니다.
        </p>
        <p>
          영어 학습을 넘어서, 글로벌 사고와 문제 해결 능력까지 <br />
          습득할 수 있는 폭넓은 학습 경험을 제공합니다.
        </p>
      </div>

      <div className="curriculum">
        <CarouselView />
      </div>
    </section>
  );
};

export default ProgramIntroduction;
