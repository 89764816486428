import React from "react";
import { styled } from "styled-components";
import { theme } from "../../../styles/theme";
import { categoryColorArray, english_book_arr, unitArr } from "./array.const";
import "./ProgramIntroductionContentsTable.css";

const CONTENT_HEIGHT = 90;

const ProgramIntroductionContentsTable: React.FC = () => {
  return (
    <>
      <h1 className="content-program-title">영어 독서 프로그램</h1>
      <Container>
        <HeadSection>
          <HeaderBox>카테고리</HeaderBox>
          <CategoryBox $bg="nuriFont">누리과정</CategoryBox>
          <CategoryBox $bg="ccssFont">CCSS</CategoryBox>
          <CategoryBox $bg="sfFont">SF</CategoryBox>
          <CategoryBox $bg="non_ficFont">Non-Fiction</CategoryBox>
          <CategoryBox $bg="adventureFont">Adventure</CategoryBox>
          <CategoryBox $bg="daily_lifeFont">Daily Life</CategoryBox>
          <CategoryBox $bg="daily_ConversationFont">
            Daily Conversation
          </CategoryBox>
        </HeadSection>
        <BodySection>
          <BodyRow>
            {unitArr.map((unit, i) => (
              <UnitBox key={i}>{unit}</UnitBox>
            ))}
          </BodyRow>
          {english_book_arr.map((row, i) => (
            <BodyRow key={i}>
              {row.map((content, j) => {
                return (
                  <ContentBox
                    $bg={categoryColorArray[i].bg}
                    $color={categoryColorArray[i].color}
                    key={j}
                  >
                    {content}
                  </ContentBox>
                );
              })}
            </BodyRow>
          ))}
        </BodySection>
      </Container>
    </>
  );
};

export default ProgramIntroductionContentsTable;

const Container = styled.div`
  width: 100%;
  padding: 0px 10px;
  display: flex;
  gap: 10px;
  box-sizing: border-box;
  margin-bottom: 100px;
  div {
    box-sizing: border-box;
  }
`;

const HeadSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const BodySection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  overflow-x: auto;
`;

const BodyRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  min-width: 1200px;
`;

const HeaderBox = styled.div`
  width: 120px;
  height: 36px;
  /* font-size: 0.9rem; */
  font-weight: 700;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: black;
  background-color: #f1f1f1;
  word-wrap: break-word;
  text-align: center;
`;

const CategoryBox = styled.div<{
  $bg: keyof typeof theme.colors;
}>`
  font-size: 0.9rem;
  font-weight: 700;
  border-radius: 10px;
  width: 120px;
  height: ${`${CONTENT_HEIGHT}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: white;
  background-color: ${({ theme, $bg }) => theme.colors[$bg]};
  word-wrap: break-word;
  text-align: center;
`;

const UnitBox = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  flex-shrink: 0;
  background-color: #dddddd;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid transparent;

  /* 첫 번째 자식일 때 */
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  /* 마지막 자식일 때 */
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: none;
  }
`;

const ContentBox = styled.div<{
  $bg: string;
  $color: string;
}>`
  box-sizing: border-box;
  width: 100%;
  height: ${`${CONTENT_HEIGHT}px`};
  background-color: ${({ theme, $bg }) => $bg};
  color: ${({ theme, $color }) => $color};
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  font-size: 0.8rem;
  align-items: center;
  text-align: center;
  word-wrap: break-all;
  border-right: 1px dashed ${({ theme, $color }) => $color}; /* 오른쪽 구분선 */
  padding: 10px;

  /* 첫 번째 자식일 때 */
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  /* 마지막 자식일 때 */
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: none; /* 마지막 자식의 구분선 제거 */
  }
`;
