import React from "react";
import "./SubProgramsGeneral.css";
// import science2_01 from "../../assets/images/subPrograms/science2_01.jpg";
// import science2_02 from "../../assets/images/subPrograms/science2_02.jpg";
// import science2_03 from "../../assets/images/subPrograms/science2_03.jpg";
// import science2_04 from "../../assets/images/subPrograms/science2_04.jpg";
// import science2_05 from "../../assets/images/subPrograms/science2_05.png";
import Footer from "../Footer/Footer";

const science2_01 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_S2_12%E1%84%80%E1%85%A2%E1%84%8B%E1%85%AF%E1%86%AF-0.png";
const science2_02 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_S2_12%E1%84%80%E1%85%A2%E1%84%8B%E1%85%AF%E1%86%AF-1.png";
const science2_03 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_S2_12%E1%84%80%E1%85%A2%E1%84%8B%E1%85%AF%E1%86%AF-2.png";
const science2_04 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_S2_12%E1%84%80%E1%85%A2%E1%84%8B%E1%85%AF%E1%86%AF-3.png";
const science2_05 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_S2_12%E1%84%80%E1%85%A2%E1%84%8B%E1%85%AF%E1%86%AF-4.png";
const science2_06 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_S2_12%E1%84%80%E1%85%A2%E1%84%8B%E1%85%AF%E1%86%AF-5.png";

const Science2 = () => {
  return (
    <>
      <section className="section-banner science2">
        <div className="image-container">
          <img src={science2_01} alt="Science2 Explanation 1" />
          <img src={science2_02} alt="Science2 Explanation 2" />
          <img src={science2_03} alt="Science2 Explanation 3" />
          <img src={science2_04} alt="Science2 Explanation 4" />
          <img src={science2_05} alt="Science2 Explanation 5" />
          <img src={science2_06} alt="Science2 Explanation 6" />
        </div>
      </section>
      <Footer /> {/* Footer 추가 */}
    </>
  );
};

export default Science2;
