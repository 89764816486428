export const fontSize = {
  xs: "0.5rem",
  sm: "0.75rem",
  base: "1rem",
  md: "1.25rem",
  lg: "1.5rem",
  xlg: "1.75rem",
};

export const fontWeight = {
  thin: 100, // 매우 얇은 굵기, 섬세한 텍스트나 장식적인 용도에 적합
  light: 300, // 가벼운 굵기, 부제목이나 강조가 필요하지 않은 텍스트에 적합
  regular: 400, // 기본 굵기, 본문 텍스트에 적합
  medium: 500, // 중간 굵기, 중요한 정보나 부제목에 적합
  bold: 700, // 굵은 굵기, 제목이나 중요한 요소에 적합
};

export const colors = {
  unitBg: "#dddddd",
  nuriFont: "#c9548f",
  nuriBg: "#f2d4e3",
  ccssFont: "#4ba5a0",
  ccssBg: "#d2e8e7",
  sfFont: "#d9a623",
  sfBg: "#f6e9c9",
  non_ficFont: "#8b7ec4",
  non_ficBg: "#e1dff0",
  adventureFont: "#e1825f",
  adventureBg: "#f8e0d7",
  daily_lifeFont: "#6dac6d",
  daily_lifeBg: "#ddecdd",
  daily_ConversationFont: "#b0745b",
  daily_ConversationBg: "#eaddd6",
};

export const fontFamily = {
  lexend_tdvf: "lexend_tdvf, sans-serif",
  lexend_black: "lexend_black, sans-serif",
  lexend_bold: "lexend_bold, sans-serif",
  lexend_extrabold: "lexend_extrabold, sans-serif",
  lexend_extralight: "lexend_extralight, sans-serif",
  lexend_light: "lexend_light, sans-serif",
  lexend_medium: "lexend_medium, sans-serif",
  lexend_regular: "lexend_regular, sans-serif",
  lexend_semibold: "lexend_semibold, sans-serif",
  lexend_thin: "lexend_thin, sans-serif",
  nanum_b: "nanum_b, sans-serif",
  nanum_eb: "nanum_eb, sans-serif",
  nanum_l: "nanum_l, sans-serif",
  nanum_r: "nanum_r, sans-serif",
  nanum_bold: "nanum_bold, sans-serif",
  nanum_extrabold: "nanum_extrabold, sans-serif",
  nanum_light: "nanum_light, sans-serif",
  nanum_regular: "nanum_regular, sans-serif",
};

export const padding = {
  signup: {
    inputX: 16,
    inputY: 8,
  },
  header: {
    x: 36,
    y: 20,
  },
  signUpForm: {
    x: 32,
    y: 20,
  },

  useInfoModal: {
    x: 32,
    y: 32,
  },
};

export const size = {
  headerHeight: 90,
  navbarMiddleWidth: 200,
  navbarLargeWidth: 250,
};

export const theme = {
  colors,
  fontSize,
  fontWeight,
  padding,
  size,
  fontFamily,
};
