import React from "react";
import "./SubProgramsGeneral.css";
// import Literacy2_01 from "../../assets/images/subPrograms/Literacy2_01.jpg";
// import Literacy2_02 from "../../assets/images/subPrograms/Literacy2_02.jpg";
// import Literacy2_03 from "../../assets/images/subPrograms/Literacy2_03.jpg";
// import Literacy2_04 from "../../assets/images/subPrograms/Literacy2_04.jpg";
// import Literacy2_05 from "../../assets/images/subPrograms/Literacy2_05.jpg";
// import Literacy2_06 from "../../assets/images/subPrograms/Literacy2_06.jpg";
// import Literacy2_07 from "../../assets/images/subPrograms/Literacy2_07.png";
import Footer from "../Footer/Footer";

const Literacy2_01 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_L2_12%E1%84%80%E1%85%A2%E1%84%8B%E1%85%AF%E1%86%AF-0.png";
const Literacy2_02 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_L2_12%E1%84%80%E1%85%A2%E1%84%8B%E1%85%AF%E1%86%AF-1.png";
const Literacy2_03 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_L2_12%E1%84%80%E1%85%A2%E1%84%8B%E1%85%AF%E1%86%AF-2.png";
const Literacy2_04 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_L2_12%E1%84%80%E1%85%A2%E1%84%8B%E1%85%AF%E1%86%AF-3.png";
const Literacy2_05 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_L2_12%E1%84%80%E1%85%A2%E1%84%8B%E1%85%AF%E1%86%AF-4.png";
const Literacy2_06 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_L2_12%E1%84%80%E1%85%A2%E1%84%8B%E1%85%AF%E1%86%AF-5.png";
const Literacy2_07 =
  "https://toddler-service-content-bucket-68.s3.ap-northeast-2.amazonaws.com/subscription_plan/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5/%E1%84%89%E1%85%A1%E1%86%BC%E1%84%89%E1%85%A6+%E1%84%91%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5_L2_12%E1%84%80%E1%85%A2%E1%84%8B%E1%85%AF%E1%86%AF-6.png";
const Literacy2 = () => {
  return (
    <>
      <section className="section-banner literacy2">
        <div className="image-container">
          <img src={Literacy2_01} alt="Literacy2 Explanation 1" />
          <img src={Literacy2_02} alt="Literacy2 Explanation 2" />
          <img src={Literacy2_03} alt="Literacy2 Explanation 3" />
          <img src={Literacy2_04} alt="Literacy2 Explanation 4" />
          <img src={Literacy2_05} alt="Literacy2 Explanation 5" />
          <img src={Literacy2_06} alt="Literacy2 Explanation 6" />
          <img src={Literacy2_07} alt="Literacy2 Explanation 7" />
        </div>
      </section>
      <Footer /> {/* Footer 추가 */}
    </>
  );
};

export default Literacy2;
