import React from "react";
import "./Hero.css";

const Hero = () => {
  // 버튼 클릭 시 실행될 함수
  const handleStartClick = () => {
    window.open("https://edu.toddlier.co.kr/signin", "_blank"); // 새 탭에서 열기
  };

  return (
    <section className="hero">
      <div className="hero-content">
        <div className="title-wrap">
          <h1>추천 도서와 함께</h1>
          <h1>영어 독서의 세계로!</h1>
          <h2>매주 다양한 카테고리에서 책을 골라 읽고,</h2>
          <h2>꾸준히 쌓아가는 영어 실력!</h2>
          {/* 버튼에 onClick 이벤트 추가 */}
          <button onClick={handleStartClick}>지금 시작하기</button>
        </div>
      </div>
    </section>
  );
};

export default Hero;
