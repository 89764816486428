import React from "react";
import "./TextModal.css";
import xbtn from "../../assets/images/x_button.png";

// TextModal Props 타입 정의
interface TextModalProps {
  title: string;
  content: string;
  onClose: () => void;
}

const TextModal: React.FC<TextModalProps> = ({ title, content, onClose }) => {
  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2 className="modal-title">{title}</h2>
        <p className="modal-text">{content}</p>
        <button className="modal-close" onClick={onClose}>
          <img src={xbtn} alt="x" />
        </button>
      </div>
    </div>
  );
};

export default TextModal;
