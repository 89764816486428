import { styled } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useRef } from "react";
import type { Swiper as SwiperType } from "swiper"; // Swiper 타입 임포트
import CarouselArrow from "../CaroselArrow/CarouselArrow";
import { carouselItem } from "./carousel.const";
const ICON_SIZE = 230;
// 모험, 과학1 예시 이미지가 없음

const ICON_375 = 100;
const ICON_500 = 150;

interface HorizonCarouselProps {
  handleIndexChange: (index: number) => void;
}
export default function HorizonCarousel({
  handleIndexChange,
}: HorizonCarouselProps) {
  const swiperRef = useRef<SwiperType | null>(null); // 타입 지정

  const handleNext = () => {
    console.log("다음", swiperRef.current);
    swiperRef.current?.slideNext();
  };

  const handlePrev = () => {
    swiperRef.current?.slidePrev();
  };

  const handleSlideTo = (index: number) => {
    swiperRef.current?.slideTo(index); // 해당 인덱스로 이동
    handleIndexChange(index); // 외부에서도 인덱스 업데이트
  };

  return (
    <SwiperWrap>
      <PrevBtnWrap>
        <CarouselArrow onClick={handlePrev} type="left" />
      </PrevBtnWrap>
      <NextBtnWrap>
        <CarouselArrow onClick={handleNext} type="right" />
      </NextBtnWrap>
      <StyledSwiper
        onSwiper={(swiper: any) => (swiperRef.current = swiper)}
        onSlideChange={(swiper: any) => handleIndexChange(swiper.activeIndex)}
        // modules={[Pagination]}
        // pagination={{ clickable: true }}
        slidesPerView={1}
        centeredSlides={true}
        spaceBetween={20}
      >
        {carouselItem.map((item, i) => (
          <SwiperSlide key={i} onClick={() => handleSlideTo(i)}>
            <Card src={item.icon} alt={`icon_${i}`} />
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </SwiperWrap>
  );
}

const SwiperWrap = styled.div`
  display: flex;
  overflow: visible;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const Card = styled.img`
  width: ${`${ICON_375}px`};
  height: ${`${ICON_375}px`};
  @media (min-width: 500px) {
    width: ${`${ICON_500}px`};
    height: ${`${ICON_500}px`};
  }
  @media (min-width: 1080px) {
    width: ${`${ICON_SIZE}px`};
    height: ${`${ICON_SIZE}px`};
  }

  object-fit: contain;
`;

const StyledSwiper = styled(Swiper)`
  width: ${`${ICON_375}px`};
  height: ${`${ICON_375}px`};
  @media (min-width: 500px) {
    width: ${`${ICON_500}px`};
    height: ${`${ICON_500}px`};
  }
  @media (min-width: 1080px) {
    width: ${`${ICON_SIZE}px`};
    height: ${`${ICON_SIZE}px`};
  }
  overflow: visible;
  .swiper-wrapper {
    gap: 20;
  }

  .swiper-slide,
  .swiper-slide-prev,
  .swiper-slide-next {
    opacity: 0.3;
    transition: opacity 0.6s ease; /* 0.3초 동안 부드럽게 변화 */
  }
  .swiper-slide-active {
    opacity: 1;
    transition: opacity 0.6s ease; /* 0.3초 동안 부드럽게 변화 */
  }
`;

const PrevBtnWrap = styled.div`
  position: absolute;
  width: 25px;
  z-index: 10;
  left: -5px;
`;

const NextBtnWrap = styled.div`
  position: absolute;
  width: 25px;
  z-index: 10;
  right: 0;
`;
