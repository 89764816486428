import React, { HTMLAttributes } from "react";
import styled from "styled-components";

interface ICarouselItem extends HTMLAttributes<HTMLDivElement> {
  imgUrl: string;
  onClick?: (...args: any) => void;
}

/**
----------------------------------------------- 🎨 스타일 🎨 -----------------------------------------------
----------------------------------------------- 🎨 스타일 🎨 -----------------------------------------------
*/
const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100%;
  cursor: pointer;
`;

const Img = styled.img`
  width: 200px;
  height: 300px;
  object-fit: cover;
  border-radius: 12px;
`;

const CarouselItem = ({ imgUrl, onClick }: ICarouselItem) => {
  return (
    <Layout onClick={onClick} className="carousel-card">
      <Img src={imgUrl} alt="아이템" />
    </Layout>
  );
};

export default CarouselItem;
