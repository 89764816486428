import React from "react";
import "./HamburgerMenu.css";

interface HamburgerMenuProps {
  programLinks: { name: string; path: string }[]; // programLinks 배열 타입 정의
  onLinkClick: (path: string) => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ programLinks, onLinkClick }) => {
  return (
    <div className="hamburger-menu">
      {programLinks.map((link, index) => (
        <a
          key={index}
          href={link.path}
          onClick={(e) => {
            e.preventDefault(); // 기본 링크 동작 방지
            onLinkClick(link.path); // 전달받은 onLinkClick 호출
          }}
        >
          {link.name}
        </a>
      ))}
    </div>
  );
};

export default HamburgerMenu;
