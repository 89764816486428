import React from "react";
import { styled } from "styled-components";

interface CarouselSelectedProps {
  src: string;
}
export default function CarouselSelected({ src }: CarouselSelectedProps) {
  return (
    <Container>
      <Image src={src} alt="example" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  object-fit: contain;
  width: 90%;
`;
