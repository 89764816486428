import { theme } from "../../../styles/theme";

export const english_book_arr = [
  [
    "Be My Friend",
    "Spring",
    "Me and My Family",
    "Town",
    "Vehicles",
    "Summer",
    "World",
    "Fall",
    "Food",
    "Winter",
    "Days",
    "Months",
  ],
  [
    "Animals",
    "Plants",
    "Environment",
    "Earth's Resources",
    "Matter",
    "Force and Energy",
    "Sound",
    "Electricity",
    "Body",
    "Weather and Seasons",
    "Natural Disasters",
    "Space",
  ],
  [
    "Dinosaurs of North America",
    "Dinosaurs of Asia",
    "European Dinosaurs",
    "Dinosaurs of South America",
    "Dinosaurs of Africa",
    "Dinosaurs of Africa",
    "Space Exploration",
    "Meeting Aliens",
    "Life on a Space Station",
    "Our First Space Adventure",
    "Living with Friendly Robots",
    "Teaming Up with Robots",
  ],
  [
    "Learning About Wild Animals",
    "Learning Colors",
    "Exploring Weather",
    "Learning Shapes",
    "Exploring Food",
    "Transportation",
    "Introducing Jobs",
    "Learning About Seasons",
    "Exploring Insects",
    "Parts of the Body",
    "Exploring Nature",
    "Understanding Emotions",
  ],
  [
    "The Enchanted Forest",
    "The Crystal Caverns",
    "The Floating Islands",
    "The Underwater Kingdom",
    "The Fire Mountains",
    "The Ice Peaks",
    "The Shadow Valley",
    "The Lost Desert",
    "The Golden Jungle",
    "The Cloud City",
    "The Moonlit Plains",
    "The Ancient Ruins",
  ],
  [
    "Morning Routines",
    "School Adventures",
    "Family Time",
    "Seasons of Fun",
    "Friendship Stories",
    "Exploring the Neighborhood",
    "Learning New Skills",
    "Celebrations and Festivals",
    "Healthy Habits",
    "Animal Friends",
    "Imaginative Play",
    "Exploring Nature",
  ],
  [
    "Greetings and Introductions",
    "At Home Morning Routine",
    "At School",
    "At the Playground",
    "Shopping with Family",
    "Holidays and Celebrations",
    "Animals and Pets",
    "Health and Safety",
    "Weather and Seasons",
    "Foods and Eating",
    "Traveling",
    "Hobbies and Interests",
  ],
];

export const categoryColorArray: { bg: string; color: string }[] = [
  {
    bg: theme.colors.nuriBg,
    color: theme.colors.nuriFont,
  },
  {
    bg: theme.colors.ccssBg,
    color: theme.colors.ccssFont,
  },
  {
    bg: theme.colors.sfBg,
    color: theme.colors.sfFont,
  },
  {
    bg: theme.colors.non_ficBg,
    color: theme.colors.non_ficFont,
  },
  {
    bg: theme.colors.adventureBg,
    color: theme.colors.adventureFont,
  },
  {
    bg: theme.colors.daily_lifeBg,
    color: theme.colors.daily_lifeFont,
  },
  {
    bg: theme.colors.daily_ConversationBg,
    color: theme.colors.daily_ConversationFont,
  },
];

export const unitArr = [
  "UNIT 1",
  "UNIT 2",
  "UNIT 3",
  "UNIT 4",
  "UNIT 5",
  "UNIT 6",
  "UNIT 7",
  "UNIT 8",
  "UNIT 9",
  "UNIT 10",
  "UNIT 11",
  "UNIT 12",
];

export const mediaCategoryArr = [
  { text: "LITERACY 1", span: 2, bg: "#ec91ab", color: "#ffffff" },
  { text: "LITERACY 2", span: 2, bg: "#c49bda", color: "#ffffff" },
  { text: "MATH", span: 2, bg: "#f29b48", color: "#ffffff" },
  { text: "SCIENCE 1", span: 2, bg: "#5877b2", color: "#ffffff" },
  { text: "SCIENCE 2", span: 2, bg: "#6aa7c5", color: "#ffffff" },
];

export const mediaContentsArr = [
  [
    { text: "Consonants / Vowels", span: 9, bg: "#d3e7db", color: "#357a6e" },
    { text: "Short Vowels Patterns", span: 6, bg: "#f6d5d1", color: "#d64541" },
    {
      text: "Long Vowel Patterns (Magic E)",
      span: 2,
      bg: "#f6e8c2",
      color: "#b68e30",
    },
    { text: "Consonant Blends", span: 2, bg: "#d5e6f2", color: "#4a90e2" },
    { text: "Consonant Digraphs", span: 2, bg: "#f2d4eb", color: "#bd3f7e" },
    { text: "Vowel Digraphs", span: 3, bg: "#e8def2", color: "#926db8" },
  ],
  [
    { text: "Consonants / Vowels", span: 7, bg: "#d3e7db", color: "#357a6e" },
    { text: "Short Vowel Patterns", span: 4, bg: "#f6d5d1", color: "#d64541" },
    {
      text: "Long Vowel Patterns (Magic E)",
      span: 4,
      bg: "#f6e8c2",
      color: "#b68e30",
    },
    { text: "Consonant Blends", span: 4, bg: "#d5e6f2", color: "#4a90e2" },
    { text: "Consonant Digraphs", span: 2, bg: "#f2d4eb", color: "#bd3f7e" },
    { text: "Vowel Digraphs", span: 3, bg: "#e8def2", color: "#926db8" },
  ],
  [
    { text: "", span: 8, bg: "#ffffff", color: "#000000" },
    { text: "Prepositions", span: 2, bg: "#f6d5d1", color: "#d64541" },
    { text: "Conjunctions", span: 2, bg: "#f6d5d1", color: "#d64541" },
    { text: "Pronouns", span: 2, bg: "#f6d5d1", color: "#d64541" },
    { text: "Articles", span: 2, bg: "#f6d5d1", color: "#d64541" },
    { text: "Verb Tenses", span: 2, bg: "#f6d5d1", color: "#d64541" },
    { text: "Plural Nouns", span: 2, bg: "#f6d5d1", color: "#d64541" },
    { text: "Capitalization", span: 2, bg: "#f6d5d1", color: "#d64541" },
    {
      text: "Subject-verb Agreement",
      span: 2,
      bg: "#f6d5d1",
      color: "#d64541",
    },
  ],
  [
    { text: "Arithmetic", span: 8, bg: "#f6d5d1", color: "#d64541" },
    { text: "Patterns and Algebra", span: 2, bg: "#f6e8c2", color: "#b68e30" },
    { text: "Measurement", span: 6, bg: "#d3e7db", color: "#357a6e" },
    { text: "Data and Statistics", span: 2, bg: "#e8def2", color: "#926db8" },
    { text: "Geometry", span: 4, bg: "#f6d5d1", color: "#d64541" },
    { text: "Arithmetic", span: 2, bg: "#f6d5d1", color: "#d64541" },
  ],
  [
    { text: "Biology", span: 4, bg: "#f6d5d1", color: "#d64541" },
    { text: "Environmental Science", span: 2, bg: "#d3e7db", color: "#357a6e" },
    { text: "Earth Science", span: 2, bg: "#d9cdbf", color: "#8a6f48" },
    { text: "Physics / Chemistry", span: 6, bg: "#f6e8c2", color: "#b68e30" },
    {
      text: "Physics / Electrical Engineering",
      span: 2,
      bg: "#d3e7db",
      color: "#357a6e",
    },
    { text: "Biology", span: 2, bg: "#f6d5d1", color: "#d64541" },
    { text: "Earth Science", span: 4, bg: "#d9cdbf", color: "#8a6f48" },
    { text: "Astronomy", span: 2, bg: "#d5e6f2", color: "#4a90e2" },
  ],
  [
    { text: "Biology", span: 4, bg: "#f6d5d1", color: "#d64541" },
    { text: "Environmental Science", span: 2, bg: "#d3e7db", color: "#357a6e" },
    { text: "Earth Science", span: 2, bg: "#d9cdbf", color: "#8a6f48" },
    { text: "Physics / Chemistry", span: 6, bg: "#f6e8c2", color: "#b68e30" },
    {
      text: "Physics / Electrical Engineering",
      span: 2,
      bg: "#d3e7db",
      color: "#357a6e",
    },
    { text: "Biology", span: 2, bg: "#f6d5d1", color: "#d64541" },
    { text: "Earth Science", span: 4, bg: "#d9cdbf", color: "#8a6f48" },
    { text: "Astronomy", span: 2, bg: "#d5e6f2", color: "#4a90e2" },
  ],
];
